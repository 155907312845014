










import { Component, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

export type ExcelLoadError = { row: number; message: string }

@Component({
  components: {
    ModalBase,
    ButtonBase,
  },
})
export default class ModalExcelLoadErrors extends Mixins(ModalBaseMethod) {
  private errors: ExcelLoadError[] = []

  public showErrors(errors: ExcelLoadError[]) {
    this.errors = errors
    this.show()
  }
}
